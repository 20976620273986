// Main app container
#app-content{
  margin: 50px 12px; // Allowing for fixed header and footer
  margin-bottom: 2rem; // Match .navbar.is-fixed-bottom
}

// Adjust Bulma footer height to be smaller
.navbar.is-fixed-bottom{
  min-height: 2rem!important;
  height: 2rem;

  .navbar-brand{
    min-height: 0;
  }
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.position-absolute {
  position: absolute;
}

.top-to-bottom {
  top: 0;
  bottom: 0;
}

.left-to-right{
  left: 0;
  right: 0;
}

.overflow-auto{
  overflow: auto;
}
