
#menuOptions{
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 3px;
  padding: 12px;
  margin: 12px 0px;
}

.cohort-table{
  margin: 32px 0;
}

.cohort-chart{
  margin: 32px 0;
}
