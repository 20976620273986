
.listing{
  .oneElement{
    padding: 6px;;
    margin-bottom: 12px;
    background-color: rgba(0,0,0,0.1);
    border-radius: 3px;

    h4 {
      margin-bottom: 0;
    }

    p {
      margin: 3px;
    }
  }
}
