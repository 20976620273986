#itemsGrouping{
  .itemsContainers{
    padding: 9px;
    border: 1px solid #00c0ef;
    border-radius: 3px;
  }
  .item{
    padding: 3px;
    margin: 2px;
    background-color: rgba(179,242,240,0.3);
    border-radius: 3px;
    border: 1px solid transparent;
    cursor:move;
  }
  .sortable-chosen {
    border: 1px solid rgba(179,242,240,0.8);
    background-color: rgba(179,242,240,0.9);
  }
  .sortable-selected {
    border: 1px solid rgba(179,242,240,1);
    background-color: rgba(179,242,240,0.6);
  }
  .sortable-ghost {
    opacity: .6;
  }

  .tinted {
    background-color: #fff6b2 !important;
  }
  
  .selected {
    background-color: #f9c7c8 !important;
    border: 1px solid blue !important;
    z-index: 1 !important;
  }
}
