
.menu-filepicker {
  .zone {
    // border: 2px solid rgba(0,0,0,0.3);
    // border-radius: 9px;
    // padding: 6px;

    border-radius: 4px;

    &.on-drag{
      background-color: rgba(0,0,0,0.2);
    }
  }
}
