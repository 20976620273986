
// Specific styling for the grid: ie. could be a theme
.cssGrid {
  div{
    padding: 3px;
  
    p {
      padding: 3px 0;
      margin: 3px 0;
    }
  }

  .headerCell {
    // border-bottom: 1px solid rgba(0,0,0,0.4);
    border-right: 1px solid rgba(0,0,0,0.2);
    font-weight: bold;
    align-self: stretch; // Vertical align for header cells

    // Allow to show full text in cell, or hide is it can not fit
    overflow: scroll;
    overflow-wrap: anywhere;

    // Wrap text in header
    white-space: normal;

    // To identify the column groups
    &.parentColumn{
      background-color: rgba(0,0,0,0.1);
      margin-left: 10px;
    }
  }

  // .leftCell{
  //   font-weight: bold;
  //   border-left: 1px solid rgba(0,0,0,0.2);
  // }

  .bordered {
    border-top: 1px solid rgba(0,0,0,0.2);
    border-right: 1px solid rgba(0,0,0,0.2);
  }
  
  .contentCell{
    cursor: pointer;
  }

  .rowGroupLevel1{
    border-top: 1px solid rgba(0,0,0,0.3);
  }

  .cell {
    &:hover{
      background-color: rgba(0,0,0,0.1);
    }
  }

  .numberColumns {
    text-align: right;
  }
}

.zenflowGrid {
  overflow: hidden; // COMBINED: Allows scrolling of inner grid elements
  .cssGrid {
    height: 100%;  // COMBINED: Allows scrolling of inner grid elements
    margin-bottom: 50px; // ensure footer does not eat the grid
  }

  // .leftColumns {
  //   // width: 200px;
  //   // font-weight: bold;
  // }

  .subRow {
    color: rgba(0,0,0,0.6);
    font-size: 0.9em;
  }
  .zero {
    color: rgba(0,0,0,0.2)!important;
  }

  .sectionSubTotal{
    color: #3c8dbc;
    border-top: 1px solid #3c8dbc;
  }
  .sectionTotal{
    font-weight: bold;
    color: #3c8dbc;
    border-top: 1px solid #3c8dbc;
  }

  .lastGroupColumn{
    border-right: 1px solid rgba(0,0,0,0.1);
    background-color: rgba(60, 141, 188, 0.15);
  }
}

// Position cells examples:
// cellElement.style['grid-row-start'] = (gridRow)
// cellElement.style['grid-row-end'] = (gridRow + 1)
// cellElement.style['grid-column'] = (columnIndex)
