.cssGridParent {
  height: 100%;  // COMBINED: Allows scrolling of inner grid elements
  margin-bottom: 50px; // ensure footer does not eat the grid
}

// Defaut styling for the grid to function
.cssGrid {
  display: grid;

  // Defining an overall grid of 2 columns
  grid-template-columns: min-content auto; // min-content to fix the left side

  .layout_top_left {
    display: grid;
  }
  .layout_top_center {
    display: grid;
    // Do not show scrollbars
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  .layout_center_left {
    display: grid;

    // Do not show scrollbars
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  .layout_center_center {
    display: grid;
  }
  //

  .scroll{
    overflow: scroll;
    // max-height: 800px; // TODO: actual screen height; Until actual screen heights is applied
  }
  .scroll-vertical{
    height: 100%; // NEEDED TO ALLOW Gird Element Scrolling
  }

  div{
    align-self: flex-start;
  }

  &.fit-content {
    width: fit-content;
  }

  .cell{
    // Content on 1 row only, with ellipsis cut
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: stretch; // Cell fills the vertical space of the row

    &.lastGroupColumn{
      width: auto;
    }

    // Do not show scrollbars
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }


  &.scrollHorizontal {
    overflow-x: scroll;
  }
}

// Position cells examples:
// cellElement.style['grid-row-start'] = (gridRow)
// cellElement.style['grid-row-end'] = (gridRow + 1) // the -end is NOT INCLUSIVE
// cellElement.style['grid-column'] = (columnIndex)
