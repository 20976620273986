
.withHoverEffect {
  .hoverShow {
    visibility: hidden;
    opacity: 0;
    width: 0;
    display: inline-block;
    transition: visibility 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
    transition: width 0.2s ease-in-out;
  }

  &:hover{
    .hoverShow{
      visibility: visible;
      opacity: 1;
      width: 50px; // Ideally it would be 'auto', but CSS transition don't work for width=auto
    }
  }
}
