
.timeAgoUI {
  .timeText {
    display: inline;
  }
  .timestamp {
    display: none;
  }

  &:hover {
    .timeText {
      display: none;
    }

    .timestamp {
      display: inline;
    }
  }
}
